import { Button } from 'components/catalyst/button'
import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { useEffect, useState } from 'react'
import { CreateIndexForm } from './create-index-form'
import { useSearchParams } from 'react-router-dom'

export function CreateIndexDialog({ children }: { children?: React.ReactNode }) {
  let [isOpen, setIsOpen] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('create') === 'true') {
      setIsOpen(true)
    }
  }, [searchParams])

  return (
    <>
      {children ? (
        <button className="w-full" onClick={() => setIsOpen(true)}>
          {children}
        </button>
      ) : (
        <Button className="max-sm:w-full" color="amber" onClick={() => setIsOpen(true)}>
          Create New Index
        </Button>
      )}

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Create New Index</DialogTitle>
        <DialogDescription>An index organizes and holds multiple vectors in Vectroid.</DialogDescription>
        <DialogBody>
          <CreateIndexForm onCancel={() => setIsOpen(false)} />
        </DialogBody>
      </Dialog>
    </>
  )
}

import { Heading } from 'components/catalyst/heading'

import { RouteNames } from '@vectroid/shared/const'
import { ApiDocsButton } from 'components/api-docs-button'
import { ShareResourceDialog } from 'modules/datasets/components/share-resource-dialog'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IndexDetailContext } from '../context'
import { DatasetStatusBadge } from 'modules/datasets/components/dataset-badge-button'

const IndexDetailTabs = [
  { label: 'Query Console', href: RouteNames.IndexDetailConsole.name },
  { label: 'Access Database', href: RouteNames.IndexDetailAccessDB.name },
  { label: 'Settings', href: RouteNames.IndexDetailSettings.name, betaFeature: true },
].map((tab) => ({ ...tab, isPageTab: true }))

export function IndexDetailHeader() {
  const { indexDetail, datasetDetail} = useContext(IndexDetailContext)

  const { pathname } = useLocation()

  const tabs = IndexDetailTabs.map((tab) => ({
    ...tab,
    current: pathname.includes(tab.href),
  }))

  return (
    <>
      <Heading
        actions={
          <>
            <ApiDocsButton path="/search-vector" />
            <ShareResourceDialog resourceName={indexDetail.name} resourceType="Index" />
          </>
        }
        pageHeading
        breadcrumb={{ label: 'Indexes', path: RouteNames.Indexes }}
        tabs={tabs}
      >
        <div className="flex items-center space-x-2">
          <span>{indexDetail.name}</span>
          <DatasetStatusBadge dataset={datasetDetail} />
        </div>
      </Heading>
    </>
  )
}

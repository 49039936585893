import { apiClient } from '@vectroid/shared/api'

export function executePlaygroundCode(code: string, endpoint: string, method: string, baseUrl: string): Promise<any> {
  try {
    const parsedCode = code ? JSON.parse(code) : undefined
    return apiClient(endpoint, method, parsedCode, { baseUrl })
  } catch (e) {
    console.log(e)
    return Promise.reject(e)
  }
}

export const getCollectionColor = (method: string): 'amber' | 'green' | 'red' => {
  const methodColors = {
    GET: 'amber',
    POST: 'green',
    DELETE: 'red',
  } as const

  return methodColors[method as keyof typeof methodColors] || 'amber'
}

import { GridLayout } from 'components/common/grid-layout/grid-layout'
import { DashboardLayout, useTheme } from 'context/theme-provider'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { Dataset } from 'modules/datasets'
import { Index, IndexWithDataset } from 'modules/indexes'
import { IndexItemGrid } from '../components/index-item-grid'
import { IndexListEmptyState } from '../components/index-list-empty-state'
import { IndexesStackLayout } from '../components/indexes-stack-layout'

export type Props = {
  loading: boolean
  indexes: Index[]
  datasets: Dataset[]
}

export function IndexListView(props: Props) {
  const { dashboardLayout } = useTheme()

  const indexesWithDatasets: IndexWithDataset[] = props.indexes.map((index) => {
    const dataset = props.datasets.find((dataset) => dataset.id === index.datasetId)!
    return { ...index, dataset }
  })

  const indexes = useSearchParamFilteredArray(indexesWithDatasets, { searchKeys: ['name'] })

  if (!props.loading && props.indexes.length === 0) {
    return <IndexListEmptyState />
  }

  if (dashboardLayout === DashboardLayout.Grid) {
    return (
      <GridLayout loading={props.loading}>
        {indexes.map((index) => (
          <IndexItemGrid index={index} key={index.id} />
        ))}
      </GridLayout>
    )
  }

  return <IndexesStackLayout indexes={indexes} loading={props.loading} />
}

import { apiClient } from '@vectroid/shared/api'
import {
  CreateDatasetRequest,
  CreateDatasetResponse,
  DescribeDatasetResponse,
  InsertDataRequest,
  InsertDataResponse,
  ListDatasetIndexesResponse,
  ListDatasetResponse,
} from '../types'

/**
 * Create a dataset
 *
 * @param {CreateDatasetRequest} values - The dataset values
 * @returns {Promise<CreateDatasetResponse>} - The created dataset
 */
export function createDatasetApi(values: CreateDatasetRequest) {
  return apiClient<CreateDatasetRequest, CreateDatasetResponse>('/dataset/create', 'POST', values)
}

/**
 * List datasets
 *
 * @returns {Promise<ListDatasetResponse>} - The list of datasets
 */
export function listDatasetsApi() {
  return apiClient<{}, ListDatasetResponse>('/dataset', 'GET')
}

/**
 * Describe a dataset
 *
 * @param datasetName {String}
 * @returns {Promise<DescribeDatasetResponse>}
 */
export function describeDatasetApi(datasetName: string) {
  return apiClient<{}, DescribeDatasetResponse>(`/dataset/${datasetName}`, 'GET')
}

/**
 * List dataset indexes
 *
 * @param datasetName {String}
 * @returns {Promise<ListDatasetIndexesResponse>}
 */
export function listDatasetIndexesApi(datasetName: string) {
  return apiClient<{}, ListDatasetIndexesResponse>(`/dataset/${datasetName}/indexes`, 'GET')
}

/**
 * Create a dataset
 *
 * @param {InsertDataRequest} values - The dataset values
 * @returns {Promise<InsertDataResponse>} - The created dataset
 */
export function insertDataApi(values: InsertDataRequest, baseUrl: string) {
  return apiClient<InsertDataRequest, InsertDataResponse>('/dataset/insert', 'POST', values, { baseUrl })
}

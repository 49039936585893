import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import {  getInputElement, useNotificationStore } from 'components/common'
import { Field, FieldTypes } from 'components/common/form/types'
import { Form as UIForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { cn } from '@vectroid/shared/utils'
import { PlusCircleIcon, TrashIcon } from 'lucide-react'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'
import { InviteUsersFormSchema } from './invite-users-form.schema'

const formSchema = InviteUsersFormSchema

export type FormSchema = z.infer<typeof formSchema>

function getFormInputRow(index: number): Field[] {
  return [
    {
      name: `users.${index}.email`,
      label: 'Email Address',
      type: FieldTypes.Text,
      placeholder: 'jane@example.com',
    },
    {
      name: `users.${index}.role`,
      fieldType: FieldTypes.Select,
      label: 'Role',
      options: [
        { label: 'Read-Only', value: 'read-only' },
        { label: 'Read-Write', value: 'read-write' },
      ],
    },
  ]
}

const defaultValues: FormSchema = {
  users: [{ email: '', role: 'read-only' }],
}

type Props = {
  onCancel?: () => void
  onSuccess?: () => void
}

export function InviteUsersForm(props: Props) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'users',
  })

  async function handleSubmit(values: FormSchema) {
    const emails = values.users.map((user) => user.email)
    if (new Set(emails).size !== emails.length) {
      setMessage('Duplicate email addresses are not allowed', {
        type: 'error',
      })
      return
    }

    console.log(values)
    // Add form submission logic here
  }

  function renderForm() {
    return (
      <UIForm {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="w-full space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="grid grid-cols-12 gap-4">
              {getFormInputRow(index).map((formField, fieldIndex) => (
                <FormField
                  key={formField.name}
                  control={form.control}
                  name={formField.name as any}
                  render={({ field }) => {
                    const inputElement = getInputElement({ ...formField, register: form.register }, field, loading)

                    return (
                      <FormItem className={cn({ 'col-span-7': fieldIndex === 0, 'col-span-4': fieldIndex === 1 })}>
                        {index === 0 && (
                          <FormLabel className="!select-text" dangerouslySetInnerHTML={{ __html: formField.label }} />
                        )}

                        <FormControl>{inputElement}</FormControl>

                        <FormMessage className="text-xs" />
                      </FormItem>
                    )
                  }}
                />
              ))}

              <div className="flex grid-cols-1 items-end justify-center self-end">
                <Button outline className="h-9" onClick={() => remove(index)} disabled={fields.length === 1}>
                  <TrashIcon size={16} />
                </Button>
              </div>
            </div>
          ))}

          <Button outline type="button" onClick={() => append({ email: '', role: 'read-only' })}>
            <PlusCircleIcon size={16} /> Add more
          </Button>

          <Divider />

          <div className="flex justify-end space-x-4">
            <Button type="button" onClick={props.onCancel} outline>
              Cancel
            </Button>

            <Button type="submit" loading={loading} disabled={loading} color="amber">
              Invite
            </Button>
          </div>
        </form>
      </UIForm>
    )
  }

  return <>{renderForm()}</>
}

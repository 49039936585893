export const JavascriptFetchSnippet = `const url = '{{url}}';
const options = {
  method: '{{method}}',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    'x-api-key': '{{apiKey}}',
  },
  body: \`{{body}}\`
};

try {
  const response = await fetch(url, options);
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}
`

export const JavascriptAxiosSnippet = `import axios from 'axios';

const options = {
  method: '{{method}}',
  url: '{{url}}',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    'x-api-key': '{{apiKey}}',
  },
  data: {{body}}
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}
`

export const JavascriptJQuerySnippet = `const settings = {
  async: true,
  crossDomain: true,
  url: '{{url}}',
  method: '{{method}}',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    'x-api-key': '{{apiKey}}',
  },
  data: {{body}}
};

$.ajax(settings).done(function (response) {
  console.log(response);
});
`

export const JavaScriptXMLHttpRequestSnippet = `const data = '{{body}}'

const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('POST', '{{url}}');
xhr.setRequestHeader('x-api-key', '{{apiKey}}');
xhr.setRequestHeader('Accept', 'application/json');
xhr.setRequestHeader('Content-Type', 'application/json');

xhr.send(data);
`

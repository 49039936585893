import { Button } from 'components/catalyst/button'
import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { RouteNames } from '@vectroid/shared/const'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CreateDatasetForm } from './create-dataset-form'

type Props = {
  onSuccess?: () => void
  onCancel?: () => void
  children?: React.ReactNode
}

export function CreateDatasetDialog(props: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  function onCancel() {
    setIsOpen(false)
    props.onCancel?.()
  }

  function onSuccess(datasetName: string) {
    setIsOpen(false)
    props.onSuccess?.()
    navigate(RouteNames.DatasetDetail.get(datasetName))
  }

  useEffect(() => {
    if (searchParams.get('create') === 'true') {
      setIsOpen(true)
    }
  }, [searchParams])

  return (
    <>
      {props.children ? (
        <span role="button" onClick={() => setIsOpen(true)}>
          {props.children}
        </span>
      ) : (
        <Button className="max-sm:w-full" color="amber" onClick={() => setIsOpen(true)}>
          Create New Dataset
        </Button>
      )}

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Create New Dataset</DialogTitle>
        <DialogDescription>Create a dataset, add your data, and start indexing and querying.</DialogDescription>
        <DialogBody>
          <CreateDatasetForm onCancel={onCancel} onSuccess={onSuccess} />
        </DialogBody>
      </Dialog>
    </>
  )
}

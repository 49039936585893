import { Heading } from 'components/catalyst/heading'
import { TabItem } from 'hooks/use-tabs'
import { CreateIndexDialog } from './create-index-dialog'
import { ApiDocsButton } from 'components/api-docs-button'

type Props = {
  tabs: TabItem[]
}

export function IndexesHeader(props: Props) {
  return (
    <>
      <Heading
        actions={
          <>
            <ApiDocsButton path="/list-indexes" />
            <CreateIndexDialog />
          </>
        }
        pageHeading
        tabs={props.tabs}
      >
        Indexes
      </Heading>
    </>
  )
}

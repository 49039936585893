import { slugify } from '@vectroid/shared/utils'
import { Input } from 'components/catalyst/input'
import { useState } from 'react'
import { Field } from '../types'

type Props = {
  formField: Field
  field: any
  loading?: boolean
  slugifyValue?: boolean
}

export function TextField({ field, formField, ...props }: Props) {
  const [value, setValue] = useState(field.value || '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value
    if (formField.slugifyValue) {
      newValue = slugify(newValue)
    }
    setValue(newValue)
    if (field.onChange) {
      const mutatedEvent = { ...e, target: { ...e.target, value: newValue } }
      field.onChange(mutatedEvent)
    }
  }

  return (
    <Input
      {...formField}
      disabled={props.loading}
      value={value}
      className="flex-1"
      placeholder={formField.placeholder || ''}
      {...field}
      onChange={handleChange}
      {...formField.register(formField.name, { valueAsNumber: formField.type === 'number' })}
    />
  )
}

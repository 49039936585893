import { Dataset } from "modules/datasets";
import { Index } from "modules/indexes";
import { useTrackRecentResources } from "../store";
import { Heading } from "components/catalyst/heading";
import { DashboardRecentItem } from "./dashboard-recent-item";
import { RouteNames } from '@vectroid/shared/const'
import { ArrowRightIcon, FolderDot, Layers2 } from "lucide-react";
import { getDatasetStatusBadgeColor } from "modules/datasets/utils";
import { Badge } from "components/catalyst/badge";
import { Link } from "react-router-dom";
import { Text } from "components/catalyst/text";

type Props = {
  datasets: Dataset[];
  indexes: Index[];
};

export function DashboadRecentlyViewed({ datasets, indexes }: Props) {
  const { recentDatasetNames, recentIndexNames } = useTrackRecentResources();

  const filteredDatasets = recentDatasetNames
    .map((name) => datasets.find((dataset) => dataset.name === name))
    .filter((dataset) => dataset !== undefined) as Dataset[];

  const filteredIndexes = recentIndexNames
    .map((name) => indexes.find((index) => index.name === name))
    .filter((index) => index !== undefined) as Index[];

  return (
    <div className="mt-3 max-w-5xl space-y-8">
      <section>
        <Heading className="flex w-full !items-center !text-lg">
          <span className="flex-1 text-base">Recent Datasets</span>
          <Link to={RouteNames.Datasets} className="mt-1 text-xs font-normal opacity-70 hover:opacity-100">
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 space-x-6">
          {filteredDatasets.map((dataset) => (
            <DashboardRecentItem
              label={dataset.name}
              key={dataset.id}
              href={RouteNames.DatasetDetail.get(dataset.name)}
              headerColor="dark:bg-amber-900/10 bg-amber-50"
              icon={
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-amber-200 dark:bg-amber-800/20">
                  <FolderDot size={16} />
                </div>
              }
            >
              <Badge color={getDatasetStatusBadgeColor(dataset.status)}>{dataset.status}</Badge>
            </DashboardRecentItem>
          ))}
          {filteredDatasets.length === 0 && (
            <div className="col-span-12">
              <Text>No recently viewed datasets...</Text>
            </div>
          )}
        </div>
      </section>

      <section>
        <Heading className="flex w-full !items-center !text-lg">
          <span className="flex-1 text-base">Recent Indexes</span>
          <Link to={RouteNames.Indexes} className="mt-1 text-xs font-normal opacity-70 hover:opacity-100">
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 space-x-6">
          {filteredIndexes.map((index) => (
            <DashboardRecentItem
              label={index.name}
              href={RouteNames.IndexDetail.get(index.name)}
              headerColor="dark:bg-sky-900/10 bg-sky-50"
              icon={
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-sky-200 dark:bg-sky-800/30">
                  <Layers2 size={16} />
                </div>
              }
            >
              <Badge>{index.similarityFunction}</Badge>
            </DashboardRecentItem>
          ))}
          {filteredIndexes.length === 0 && (
            <div className="col-span-12">
              <Text>No recently viewed indexes</Text>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

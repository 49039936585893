import { CloudProvider } from 'modules/datasets/const'

export function formatCloudProvider(provider: CloudProvider) {
  const providerMap: Record<CloudProvider, string> = {
    [CloudProvider.AWS]: 'AWS',
    [CloudProvider.GCP]: 'GCP',
    [CloudProvider.AZURE]: 'Azure',
  }

  return providerMap[provider]
}

function getIconPath(provider: CloudProvider) {
  const iconMap: Record<CloudProvider, string> = {
    [CloudProvider.AWS]: 'aws.svg',
    [CloudProvider.GCP]: 'gcp.svg',
    [CloudProvider.AZURE]: 'azure.svg',
  }

  return `${process.env.PUBLIC_URL}/static/icons/providers/${iconMap[provider]}`
}

export function CloudProviderIcon({ provider = CloudProvider.GCP }: { provider: CloudProvider }) {
  return (
    <span className="inline-flex items-center justify-center space-x-3">
      <img width={18} src={getIconPath(provider)} alt={formatCloudProvider(provider)} />
      <span>{formatCloudProvider(provider)}</span>
    </span>
  )
}

export enum SimilarityFunctions {
  Cosine = 'COSINE',
  Euclidean = 'EUCLIDEAN',
  DotProduct = 'DOT_PRODUCT',
}

export function getSimilarityFunctionOptions() {
    return [
      { label: 'Cosine', value: SimilarityFunctions.Cosine },
      { label: 'Euclidean', value: SimilarityFunctions.Euclidean },
      { label: 'Dot Product', value: SimilarityFunctions.DotProduct },
    ]
}
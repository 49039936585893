import { z } from 'zod'

const pwdSchema = z
  .string()
  .min(8, { message: 'Password must be at least 8 characters long' })
  .max(50, { message: 'Password cannot exceed 50 characters' })
  .regex(/[a-z]/, { message: 'Password must include a lowercase letter' })
  .regex(/[A-Z]/, { message: 'Password must include an uppercase letter' })
  .regex(/[0-9]/, { message: 'Password must include a number' })

export const ResetPasswordFormSchema = z.object({
  code: z.string().min(6, { message: 'Code must be at least 6 characters long' }),
  password: pwdSchema,
  passwordConfirmation: pwdSchema,
})

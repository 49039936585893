import { RouteNames } from '@vectroid/shared/const'
import { BadgeButton } from 'components/catalyst/badge'
import { ColumnDefinition, CommonTable } from 'components/common'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import { Link } from 'react-router-dom'
import { IndexWithDataset } from '../types'

type Props = {
  loading: boolean
  indexes: IndexWithDataset[]
}

export function IndexesStackLayout(props: Props) {
  const filteredIndexes = useSearchParamFilteredArray(props.indexes, { searchKeys: ['name'] })

  const linkify = (href: string, text: string) => {
    return <BadgeButton href={href}>{text}</BadgeButton>
  }

  const columns: ColumnDefinition<IndexWithDataset>[] = [
    { key: 'id', header: 'ID' },
    {
      key: 'name',
      header: 'Index Name',
      primary: true,
      cell: (row) => (
        <Link
          className="-m-4 block px-4 py-2.5 font-semibold hover:bg-zinc-100 dark:hover:bg-zinc-800"
          to={RouteNames.IndexDetail.get(row.name)}
        >
          {row.name}
        </Link>
      ),
    },
    {
      key: 'dataset.name',
      header: 'Dataset Name',
      cell: (row) => <DatasetBadgeButton dataset={row.dataset} />,
    },
    { key: 'dimensions', header: 'Dimension', cell: (row) => row.dataset.dimensions },
    { key: 'similarityFunction', header: 'Similarity Function' },
    { key: 'actions', header: 'Actions' },
  ]

  return <CommonTable<IndexWithDataset> columns={columns} data={filteredIndexes} loading={props.loading} />
}

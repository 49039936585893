import { useDocsPanel } from 'context/docs-provider'
import { FileCode2 } from 'lucide-react'
import React from 'react'
import { Button } from './catalyst/button'

export const ApiDocsButton = React.forwardRef<
  HTMLButtonElement,
  { path: string }
>(({ path }, ref) => {
  const { openDocsPanel } = useDocsPanel()

  function showDocs() {
    openDocsPanel(path)
  }

  return (
    <Button ref={ref} onClick={showDocs} className="max-sm:w-full" outline>
      <FileCode2 size={16} /> API Docs
    </Button>
  )
})

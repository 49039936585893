import { cn } from '@vectroid/shared/utils'
import { BetaFeature } from 'components/beta-feature'
import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { DropdownButton } from 'components/catalyst/dropdown'
import { CommonDropdown } from 'components/common'
import { InviteViaLink } from 'components/invite/invita-via-link-form'
import { InviteUsersForm } from 'components/invite/invite-users-form'
import { ChevronDown, LinkIcon, MailIcon, Share2 } from 'lucide-react'
import { useState } from 'react'

type Props = { children?: React.ReactNode; resourceType: string; resourceName: string }

enum ShareMethod {
  Email = 'email',
  Link = 'link',
}

export function ShareResourceDialog({ resourceType, resourceName }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [shareMethod, setShareMethod] = useState<ShareMethod>(ShareMethod.Email)

  return (
    <>
      <CommonDropdown
        anchor="bottom end"
        menuItems={[
          {
            key: 'invite-people',
            label: (
              <span className="flex items-center space-x-4">
                <MailIcon size={16} />
                <span>Invite with email</span>
              </span>
            ),
            onClick: () => {
              setShareMethod(ShareMethod.Email)
              setIsOpen(true)
            },
          },
          {
            key: 'share-via-link',
            label: (
              <span className="flex items-center space-x-4">
                <LinkIcon size={16} />
                <span>Share via invite link</span>
              </span>
            ),
            onClick: () => {
              setShareMethod(ShareMethod.Link)
              setIsOpen(true)
            },
          },
        ]}
      >
        <BetaFeature>
          <DropdownButton>
            <Share2 strokeWidth={2.5} size={16} className="mr-1" /> Share {resourceType}{' '}
            <ChevronDown strokeWidth={2.5} size={16} />
          </DropdownButton>
        </BetaFeature>
      </CommonDropdown>

      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        className={cn({
          '!max-w-lg': shareMethod === ShareMethod.Link,
          '!max-w-xl': shareMethod === ShareMethod.Email,
        })}
      >
        <DialogTitle>{shareMethod === ShareMethod.Email ? 'Invite people' : 'Share via invite link'}</DialogTitle>
        <DialogDescription>
          <p
            dangerouslySetInnerHTML={{
              __html:
                shareMethod === ShareMethod.Email
                  ? `Invite people to collaborate on <b class="text-black dark:text-white">${resourceName}</b>.`
                  : `Share this link to invite people to collaborate on <b class="text-black dark:text-white">${resourceName}</b>.`,
            }}
          />
        </DialogDescription>
        <DialogBody>
          {shareMethod === ShareMethod.Email && <InviteUsersForm onCancel={() => setIsOpen(false)} />}
          {shareMethod === ShareMethod.Link && <InviteViaLink onDone={() => setIsOpen(false)} />}
        </DialogBody>
      </Dialog>
    </>
  )
}

import { z } from 'zod'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { Field, FieldTypes } from 'components/common/form/types'
import { delay } from '@vectroid/shared/utils'
import { useDatasetCreateStore, useDatasetCreateWizardStore } from 'modules/datasets/store/dataset-create.store'
import { Index } from 'modules/indexes'
import { CreateIndexFormSchema } from 'modules/indexes/schema'
import { useState } from 'react'

const formSchema = CreateIndexFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  datasetName = 'datasetName',
  indexName = 'indexName',
  similarity = 'similarity',
  configId = 'configId',
  hnswConnections = 'hnswConnections',
  hnswBeamWidth = 'hnswBeamWidth',
}

export function CreateIndexStepForm() {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const { setIndex, dataset } = useDatasetCreateStore()
  const { handleNext, handlePrevious } = useDatasetCreateWizardStore()

  const formFields: Field[] = [
    {
      name: FieldNames.datasetName,
      fieldType: FieldTypes.Select,
      options: [{ label: dataset!.name, value: dataset!.id! }],
      label: 'Source Dataset',
      value: dataset?.id,
    },
    { name: FieldNames.indexName, label: 'Index Name', autoFocus: true, type: FieldTypes.Text },
    {
      name: FieldNames.similarity,
      fieldType: FieldTypes.Select,
      options: [{ label: 'Cosine', value: 'cosine' }],
      label: 'Similarity Function',
    },
  ]

  const defaultValues: FormSchema = {
    [FieldNames.datasetName]: '',
    [FieldNames.indexName]: '',
    [FieldNames.similarity]: 'cosine',
    [FieldNames.hnswConnections]: 32,
    [FieldNames.hnswBeamWidth]: 200,
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      // TODO: Implement dataset creation API
      await delay()
      setIndex({ id: 1, ...values } as any as Index)
      handleNext()
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onCancel={handlePrevious}
      onSubmit={onSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create Index & Continue"
      cancelBtnText="Back"
      customActions={[{ label: 'Skip', onClick: handleNext }]}
    />
  )
}

export enum FieldTypes {
  // Primitive types
  Text = 'text',
  Select = 'select',

  // Complex types
  DatasetSelect = 'dataset-select',
}

export type Field = {
  name: string
  label: string
  autoFocus?: boolean
  type?: string
  fieldType?: FieldTypes
  placeholder?: string
  value?: string | number
  slugifyValue?: boolean
  renderHint?: (value: string) => string | undefined
  groupId?: string
  register?: any
} & FieldProps

export type SelectOption = { label: string; value: any; disabled?: boolean }

export type FieldProps =
  | { fieldType?: FieldTypes.Text; options?: never }
  | { fieldType: FieldTypes.Select; options: SelectOption[] }
  | { fieldType: FieldTypes.DatasetSelect; options?: never }

export type GroupConfig = {
  title: string
  id: string
  collapsed?: boolean
}

import { cn } from '@vectroid/shared/utils'
import { BetaFeature } from 'components/beta-feature'
import Breadcrumb from 'components/breadcrumb'
import { TabItem } from 'hooks/use-tabs'
import { useSearchParams } from 'react-router-dom'
import { Divider } from './divider'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './navbar'
import { Text } from './text'

type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  actions?: React.ReactNode
  description?: string
  tabs?: TabItem[]
  divider?: boolean
  breadcrumb?: { label: string; path: string }
  pageHeading?: boolean
} & React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>

export function Heading({
  className,
  actions,
  tabs,
  description,
  breadcrumb,
  level = 1,
  divider = true,
  pageHeading = false,
  ...props
}: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`
  const [, setSearchParams] = useSearchParams()

  function renderTabs() {
    if (tabs?.length) {
      return (
        <>
          <Navbar>
            <NavbarSection>
              {tabs?.map((tab, index) => (
                <BetaFeature key={tab.label} enabled={tab.betaFeature ?? false}>
                  <NavbarItem
                    onClick={() => !tab?.isPageTab && setSearchParams({ tab: tab.href })}
                    {...(tab?.isPageTab && { href: tab.href })}
                    current={tab.current}
                  >
                    {tab.label}
                  </NavbarItem>
                </BetaFeature>
              ))}
            </NavbarSection>
            <NavbarSpacer />
          </Navbar>
          <Divider soft />
        </>
      )
    }

    if (!pageHeading && divider) {
      return <Divider soft className="my-6" />
    }

    return <div className="my-6" />
  }

  return (
    <>
      <header
        className={cn({
          '-m-6 mb-6 border-zinc-950/10 bg-white p-6 pb-0 dark:border-white/15 dark:bg-black': pageHeading,
          'w-full': !pageHeading,
        })}
      >
        <div
          className={cn('flex w-full justify-between gap-4 max-sm:block', {
            'items-start': pageHeading,
            'items-center': !pageHeading,
          })}
        >
          <div className="flex-1 max-sm:mb-4">
            <div className="flex items-center">
              {breadcrumb && (
                <>
                  <Breadcrumb breadcrumb={breadcrumb} />
                  <span className="mx-2">/</span>
                </>
              )}

              <Element
                {...props}
                className={cn(
                  'text-2xl/8 font-semibold text-zinc-950 dark:text-white sm:text-xl/8',
                  pageHeading && '!text-xl/8',
                  className
                )}
              />
            </div>

            {description && <Text className="text-sm">{description}</Text>}
          </div>
          {actions && <div className="flex items-center justify-end gap-4">{actions}</div>}
        </div>

        {renderTabs()}

        {pageHeading && (
          <div className="-mx-6">
            <Divider />
          </div>
        )}
      </header>
    </>
  )
}

export function Subheading({ className, level = 2, ...props }: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`

  return (
    <Element
      {...props}
      className={cn('text-base/7 font-semibold text-zinc-950 dark:text-white sm:text-sm/6', className)}
    />
  )
}

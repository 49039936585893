import { SimilarityFunctions } from '../const'
import { CollectionType } from './collection-types'

const INDEX_COLLECTIONS = {
  [CollectionType.CREATE_INDEX]: {
    method: 'POST',
    label: 'Create Index',
    endpoint: '/api/v1/index/create',
    type: CollectionType.CREATE_INDEX,
    snippets: {
      json: `{
  "datasetName": "",
  "indexName": "",
  "similarity": "${SimilarityFunctions.Cosine}",
  "config": {
    "id": 1,
    "name": "",
    "hnswConfig": {
      "hnswConnections": 1,
      "hnswBeamWidth": 1
    }
  }
}`,
    },
  },

  [CollectionType.SEARCH_VECTOR]: {
    method: 'POST',
    label: 'Search Vector',
    endpoint: '/api/v1/index/query',
    type: CollectionType.SEARCH_VECTOR,
    snippets: {
      json: `{
  "indexName": "{{indexName}}",
  "vector": [1, 3, 7],
  "k": 10
}`,
    },
  },

  [CollectionType.LIST_INDEXES]: {
    method: 'GET',
    label: 'List Indexes',
    endpoint: '/api/v1/index',
    type: CollectionType.LIST_INDEXES,
  },

  [CollectionType.DESCRIBE_INDEX]: {
    method: 'GET',
    label: 'Describe Index',
    endpoint: '/api/v1/index/{indexName}',
    type: CollectionType.DESCRIBE_INDEX,
  },

  [CollectionType.DROP_INDEX]: {
    method: 'DELETE',
    label: 'Drop Index',
    endpoint: '/api/v1/drop',
    disabled: true,
    type: CollectionType.DROP_INDEX,
    snippets: {
      json: `{
  "indexId": {{indexId}}
}`,
    },
  },
}

export default INDEX_COLLECTIONS

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table'
import { Text } from 'components/catalyst/text'
import { StackedItemSkeleton } from 'components/skeletons'
import { cn } from '@vectroid/shared/utils'
import React from 'react'

export type ColumnDefinition<T> = {
  key: keyof T | 'actions' | string
  header: string
  cell?: (row: T) => React.ReactNode
  className?: string
  primary?: boolean
}

export interface CommonTableProps<T> {
  columns: ColumnDefinition<T>[]
  data: T[]
  onRowClick?: (row: T) => void
  setRowLink?: (row: T) => string
  striped?: boolean
  dense?: boolean
  bleed?: boolean
  grid?: boolean
  loading?: boolean
  className?: string
  emptyState?: React.ReactNode
}

export function CommonTable<T extends Record<string, any>>({
  columns,
  data,
  striped = false,
  dense = true,
  bleed = false,
  grid = true,
  loading = false,
  className,
  onRowClick,
  setRowLink,
  emptyState,
}: CommonTableProps<T>) {
  if (loading) {
    return (
      <>
        {new Array(6).fill(null).map((index, i) => (
          <StackedItemSkeleton key={`ItemStackedItemSkeleton-${i}`} />
        ))}
      </>
    )
  }

  return (
    <Table striped={striped} dense={dense} bleed={bleed} grid={grid} className={cn(className, 'relative')}>
      {/* Header */}
      <TableHead>
        <TableRow>
          {columns.map((col) => (
            <TableHeader key={String(col.key)}>{col.header}</TableHeader>
          ))}
        </TableRow>
      </TableHead>

      {/* Body */}
      <TableBody>
        {data.length === 0 && (
          <TableRow>
            <TableCell>{emptyState ?? 'No resources found.'}</TableCell>
          </TableRow>
        )}

        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex} onClick={onRowClick ? () => onRowClick(row) : undefined} href={setRowLink?.(row)}>
            {columns.map((col) => (
              <TableCell key={String(col.key)}>
                {col.cell ? (
                  col.cell(row)
                ) : (
                  <Text
                    className={cn({
                      'font-semibold text-black dark:text-white': col.primary,
                    })}
                  >
                    {row[col.key] ?? '-'}
                  </Text>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

import { z } from 'zod'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { Field, FieldTypes } from 'components/common/form/types'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { CloudProvider, Region } from 'modules/datasets/const'
import { createDatasetApi } from 'modules/datasets/services'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { DatasetCreateSchema } from '../schema/dataset-create.schema'
import { useDatasetStore } from '../store'

const formSchema = DatasetCreateSchema

export type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  name = 'name',
  dimensions = 'dimensions',
  cloud = 'cloud',
  region = 'region',
}

const formFields: Field[] = [
  {
    name: FieldNames.name,
    label: 'Dataset Name',
    type: FieldTypes.Text,
    autoFocus: true,
    slugifyValue: true,
    renderHint: (value: string) => (value ? `Your dataset will be created as "<b>${value}</b>"` : undefined),
  },
  { name: FieldNames.dimensions, fieldType: FieldTypes.Text, label: 'Dimension' },
  {
    name: FieldNames.cloud,
    fieldType: FieldTypes.Select,
    options: [{ label: 'GCP', value: CloudProvider.GCP }],
    label: 'Cloud',
  },
  {
    name: FieldNames.region,
    fieldType: FieldTypes.Select,
    options: [{ label: 'us-east1', value: Region.US_EAST }],
    label: 'Region',
  },
]

const defaultValues: FormSchema = {
  [FieldNames.name]: '',
  [FieldNames.dimensions]: '',
  [FieldNames.cloud]: CloudProvider.GCP,
  [FieldNames.region]: Region.US_EAST,
}

type Props = {
  onCancel?: () => void
  onSuccess?: (datasetName: string) => void
}

export function CreateDatasetForm(props: Props) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const { fetchDatasets } = useDatasetStore()

  async function handleSubmit(values: FormSchema, form: UseFormReturn<any>) {
    try {
      setLoading(true)
      const dataset = {
        ...values,
        dimensions: Number(values.dimensions),
      }

      const response = await createDatasetApi(dataset)
      if (response.data.id) {
        form.reset()
        setMessage('It may take a while for your dataset to be ready.', {
          title: 'Dataset created successfully.',
          type: 'success',
        })
        props?.onSuccess?.(values.name)
        fetchDatasets()
      }
    } catch (error: any) {
      setMessage(error?.response?.data?.message, {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create & Continue"
      onCancel={props?.onCancel}
    />
  )
}

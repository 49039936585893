import { Card } from './catalyst/card'
import { Table, TableBody, TableCell, TableRow } from './catalyst/table'
import { Skeleton } from './ui/skeleton'

export function CodeSkeleton() {
  return (
    <div className="space-y-4 p-2">
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
    </div>
  )
}

export function GridItemSkeleton() {
  return (
    <Card className="space-y-4">
      <Skeleton className="h-2 w-20" />
      <Skeleton className="h-20" />
      <div className="flex justify-between">
        <Skeleton className="h-3 w-32" />
        <Skeleton className="h-3 w-16 opacity-30" />
      </div>
      <Skeleton className="h-1 w-52" />
    </Card>
  )
}

export function StackedItemSkeleton() {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton className="h-4 w-16" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-48" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-24" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-24" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-12" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function DetailPageSkeleton() {
  return (
    <div className="space-y-8 opacity-20">
      <div className="space-y-4">
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-6 space-y-4">
          <Skeleton className="h-[250px] w-full" />
        </div>
        <div className="col-span-6 space-y-4">
          <Skeleton className="h-[250px] w-full" />
        </div>
        <div className="col-span-6 space-y-4">
          <Skeleton className="h-[250px] w-full" />
        </div>
        <div className="col-span-6 space-y-4">
          <Skeleton className="h-[250px] w-full" />
        </div>
      </div>
    </div>
  )
}

export function DashboardSkeleton() {
  return (
    <div className="space-y-8">
      {/* Recent Datasets Section */}
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <Skeleton className="h-6 w-32" /> {/* "Recent Datasets" title */}
          <Skeleton className="h-4 w-16" /> {/* "View all" link */}
        </div>
        <Card className="p-4">
          <div className="flex items-center space-x-3">
            <Skeleton className="h-8 w-8" /> {/* Folder icon */}
            <div className="space-y-2 flex-1">
              <Skeleton className="h-5 w-24" /> {/* Dataset name */}
              <Skeleton className="h-4 w-16" /> {/* "ACTIVE" status */}
            </div>
            <Skeleton className="h-6 w-6" /> {/* Arrow icon */}
          </div>
        </Card>
      </div>

      {/* Recent Indexes Section */}
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <Skeleton className="h-6 w-32" /> {/* "Recent Indexes" title */}
          <Skeleton className="h-4 w-16" /> {/* "View all" link */}
        </div>
        <div className="grid grid-cols-3 gap-4">
          {[1, 2, 3].map((i) => (
            <Card key={i} className="p-4">
              <div className="flex items-center space-x-3">
                <Skeleton className="h-8 w-8" /> {/* Index icon */}
                <div className="space-y-2 flex-1">
                  <Skeleton className="h-5 w-24" /> {/* Index name */}
                  <Skeleton className="h-4 w-24" /> {/* Index type */}
                </div>
                <Skeleton className="h-6 w-6" /> {/* Arrow icon */}
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}
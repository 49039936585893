import { BadgeProps } from 'components/catalyst/badge'
import { DatasetStatus } from '../const'

export function getDatasetStatusBadgeColor(status?: DatasetStatus): BadgeProps['color'] {
  switch (status) {
    case DatasetStatus.ACTIVE:
      return 'green'
    case DatasetStatus.PROVISIONING:
      return 'orange'
    default:
      return 'zinc'
  }
}

export function createRandomVectors(count: number, dimension: number) {
  const vectors = []
  for (let i = 0; i < count; i++) {
    const vector = []
    for (let j = 0; j < dimension; j++) {
      vector.push(parseFloat((Math.random() * 2).toFixed(2)))
    }
    vectors.push({ id: `id-${Math.random().toString(16).substring(2, 8)}${Math.floor(Math.random() * 1000)}`, vector })
  }
  return vectors
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function generateMockVectors(count: number, dimension: number) {
  const randomVectors = createRandomVectors(count, dimension || 3)
  const mockVectors = `[\n${randomVectors.map((v) => `  {\n    "id": "${v.id}",\n    "vector": [${v.vector.join(', ')}]\n  }`).join(',\n')}\n]`

  return mockVectors
}

import { RouteNames } from '@vectroid/shared/const'
import { Card } from 'components/catalyst/card'
import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'
import { DatasetBadgeButton } from 'modules/datasets/components/dataset-badge-button'
import { IndexWithDataset } from 'modules/indexes'
import { Link } from 'react-router-dom'

type Props = {
  index: IndexWithDataset
}

export function IndexItemGrid(props: Props) {
  return (
    <Link to={RouteNames.IndexDetail.get(props.index.name)} className="group">
      <Card className="max-w-[520px]">
        <div className="space-y-3">
          <Text className="flex-1 font-semibold text-zinc-900 transition-all group-hover:text-sky-600 dark:text-zinc-300 group-hover:dark:text-sky-400 sm:text-base">
            {props.index.name}
          </Text>
          <Text className="!text-xs">Dimension: {props?.index?.dataset.dimensions}</Text>
          <Text className="!text-xs">Similarity: {props?.index?.similarityFunction}</Text>
        </div>

        <Divider soft className="my-2" />
        <div className="flex items-center gap-2">
          <Text className="!text-xs">Dataset:</Text>
          <DatasetBadgeButton dataset={props?.index?.dataset} />
        </div>
      </Card>
    </Link>
  )
}

import { z } from 'zod'

import { resetPasswordApi } from '@vectroid/shared/auth'
import { RouteNames } from '@vectroid/shared/const'
import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { useState } from 'react'
import ResetPasswordForm from '../components/reset-password-form'
import { ForgotPasswordFormSchema } from '../schema/forgot-password.schema'

const formSchema = ForgotPasswordFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  Email = 'email',
}

const formFields = [{ name: FieldNames.Email, label: 'Email', type: 'email', autoFocus: true }]

function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)
  const [requestId, setRequestId] = useState('')

  const defaultValues: FormSchema = {
    email: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      const response = await resetPasswordApi(values)
      const { requestId: _requestId } = response.data

      if (!_requestId) {
        setMessage('Your password could not be reset. Please try again.', {
          type: 'error',
        })
        return
      }

      setRequestId(_requestId)
      setShowResetPasswordForm(true)
    } catch (e: any) {
      console.log(e)
      setMessage(e?.response?.data?.message ?? GENERIC_ERROR_MESSAGE, {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  function resendCode() {
    setRequestId('')
    setShowResetPasswordForm(false)
  }

  function renderContent() {
    if (showResetPasswordForm) {
      return <ResetPasswordForm requestId={requestId} resendCode={resendCode} />
    }

    return (
      <>
        <Heading level={1}>Forgot your password?</Heading>
        <Form
          formFields={formFields}
          formSchema={formSchema}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          loading={loading}
          actionBtnText="Reset Password"
          inlineErrorMessages
          actionBtnProps={{ color: 'zinc', className: 'w-full' }}
        />
      </>
    )
  }

  return (
    <>
      {renderContent()}

      <Button outline href={RouteNames.Login}>
        Back to Login
      </Button>
    </>
  )
}

export default ForgotPasswordPage

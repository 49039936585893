import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { RouteNames } from '@vectroid/shared/const'
import { Banner } from 'components/banner'
import { DetailPageSkeleton } from 'components/skeletons'
import { useTrackRecentResources } from 'modules/dashboard/store'
import { DatasetStatus } from 'modules/datasets'
import { IndexDetailHeader } from 'modules/indexes/components/index-detail-header'
import { IndexDetailContext } from 'modules/indexes/context'
import { useIndexDetail } from 'modules/indexes/store/index-detail.store'

function IndexDetailPage() {
  const params = useParams()
  const navigate = useNavigate()
  useTrackRecentResources()

  const indexName = params.indexName!
  if (!indexName) {
    throw new Error('Index name is required')
  }

  const { index: indexDetail, dataset: datasetDetail, indexLoading, describeIndex } = useIndexDetail(indexName)

  useEffect(() => {
    describeIndex()
  }, [])

  if (indexLoading) {
    return <DetailPageSkeleton />
  } else if (!indexDetail || !datasetDetail) {
    return (
      <Banner
        type="error"
        message="Index not found"
        actionBtns={[{ label: 'Go Dashboard', onClick: () => navigate(RouteNames.Dashboard) }]}
      />
    )
  }

  function renderContent() {
    if (datasetDetail?.status === DatasetStatus.PROVISIONING) {
      return (
        <Banner
          type="warning"
          message="The dataset this index belongs to is being provisioned now. Please check back later..."
        />
      )
    }

    return <Outlet />
  }

  return (
    <IndexDetailContext.Provider value={{ indexDetail, datasetDetail }}>
      <IndexDetailHeader />

      {renderContent()}
    </IndexDetailContext.Provider>
  )
}

export default IndexDetailPage

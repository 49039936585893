import { Heading } from 'components/catalyst/heading'
import { CreateIndexForm } from '../components/create-index-form'
import { ApiDocsButton } from 'components/api-docs-button'

function CreateIndexPage() {
  return (
    <>
      <Heading
        pageHeading
        actions={
          <>
            <ApiDocsButton path="/create-index" />
          </>
        }
        description="An index organizes and holds multiple vectors in Vectroid."
      >
        Create New Index
      </Heading>

      <CreateIndexForm />
    </>
  )
}

export default CreateIndexPage

import { z } from 'zod'

export const LoginFormSchema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .max(50, { message: 'Email cannot exceed 50 characters' }),
  password: z
    .string()
    .min(8, { message: 'Password must be at least 8 characters long' })
    .max(50, { message: 'Password cannot exceed 50 characters' })
    .regex(/[a-z]/, { message: 'Password must include a lowercase letter' })
    .regex(/[A-Z]/, { message: 'Password must include an uppercase letter' })
    .regex(/[0-9]/, { message: 'Password must include a number' }),
})

export const CheckEmailFormSchema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .max(50, { message: 'Email cannot exceed 50 characters' }),
})
